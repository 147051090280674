import { useState, useEffect, useCallback } from "react";
import { AddressOption } from "../types/business";

export const useAddress = () => {
	const [currentAddress, setCurrentAddress] = useState<string>();
	const [address, setAddress] = useState<AddressOption | undefined>();

	useEffect(() => {
		const storedAddress = localStorage.getItem("address");
		if (storedAddress) {
			setAddress(storedAddress as unknown as AddressOption);
		}
	}, []);

	useEffect(() => {
		const getLocation = async () => {
			if (!navigator.geolocation) {
				console.error("Geolocation is not supported by this browser.");
				return;
			}

			try {
				const position = await new Promise<GeolocationPosition>(
					(resolve, reject) => {
						navigator.geolocation.getCurrentPosition(resolve, reject);
					}
				);

				const { latitude, longitude } = position.coords;
				const locationData = {
					lat: latitude.toFixed(6),
					lng: longitude.toFixed(6),
				};

				localStorage.setItem("latlon", JSON.stringify(locationData));

				const response = await fetch(
					`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&zoom=18`
				);
				const data = await response.json();
				setCurrentAddress(data.display_name);
			} catch (error) {
				console.error("Error fetching location/address: ", error);
			}
		};

		getLocation();
	}, []);

	const handleAddressChange = async (value: AddressOption | null) => {
		if (!value) {
			setAddress(undefined);
			localStorage.removeItem("previousAddress");
			return;
		}

		setAddress(value);
		localStorage.setItem("previousAddress", JSON.stringify(value));

		const placeId = value.value.place_id;
		const service = new window.google.maps.places.PlacesService(
			document.createElement("div")
		);

		service.getDetails({ placeId }, (place: any, status) => {
			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
				const locationData = {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng(),
				};
				localStorage.setItem("latlon", JSON.stringify(locationData));
			}
		});
	};

	return {
		currentAddress,
		address,
		setAddress,
		handleAddressChange,
	};
};

import React from "react";
import logo from "../../assets/images/logo.png";
import { isIOS } from "react-device-detect";

export const BusinessHeader: React.FC = () => (
	<div className="border-b pb-4 sm:w-[30rem] sm:mx-auto">
		<div className="mx-auto">
			<div className="px-4 py-3 bg-[#E7E4F7] rounded-2xl flex justify-between items-center">
				<div className="flex items-center gap-x-2 sm:w-1/2">
					<img src={logo} alt="Beauty Butler" className="w-12 h-12" />
					<div>
						<p className="sm:text-sm text-xs font-normal heading-font">
							Beauty Butler
						</p>
						<p className="sm:text-xs text-[10px] text-[#667085] font-normal">
							Beauty treatments direct to your doorstep
						</p>
					</div>
				</div>
				<a
					className="bg-[#8877D8] text-white rounded-lg py-2 sm:px-4 sm:w-auto w-44 font-medium text-xs text-center ms-2"
					href={`${
						isIOS
							? "https://apps.apple.com/us/app/beauty-butler-app/id6608976314"
							: "https://play.google.com/store/apps/details?id=com.beautybutler.use"
					}`}
					target="_blank"
					rel="noreferrer"
				>
					Download App
				</a>
			</div>
		</div>
	</div>
);

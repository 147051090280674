import React, { createContext, useState, useContext, ReactNode } from "react";

interface TimeContextType {
	selectedTime: string | null;
	formattedTime: string | null;
	setTime: (time: string) => void;
}

const TimeContext = createContext<any>(undefined);

export const convertTo12HourFormat = (time24: string = ""): string => {
	const [hours, minutes, seconds] = time24.split(":").map(Number);
	const date = new Date();
	date.setHours(hours, minutes, seconds);

	return date.toLocaleTimeString([], {
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	});
};

interface TimeProviderProps {
	children: ReactNode;
}

export const TimeProvider: React.FC<TimeProviderProps> = ({ children }) => {
	const [selectedTime, setSelectedTime] = useState<string | null>(null);
	const [formattedTime, setFormattedTime] = useState<string | null>(null);

	const handleSetTime = (time: string) => {
		setSelectedTime(time);
		const formatted = convertTo12HourFormat(time);
		setFormattedTime(formatted);
	};

	return (
		<TimeContext.Provider
			value={{
				selectedTime,
				formattedTime,
				setTime: handleSetTime,
			}}
		>
			{children}
		</TimeContext.Provider>
	);
};

export const useTime = (): TimeContextType => useContext(TimeContext);

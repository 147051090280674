import React, { useEffect, useCallback, useMemo, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { handleFinalStep, sendOtp } from "../../Services/ApiService";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import { auth, googleProvider, signInWithGoogle } from "../../config/firebase";
import googleicon from "../../assets/images/google-social-icon.png"

const INITIAL_PHONE_DATA = {
	number: "",
	countryCode: "ng",
	dialCode: "234",
	inputValue: "",
	isValid: false,
};

const API_URL = "https://staging-api.mybeautybutler.com/customer/checkEmail";

export default function Login() {
	const navigate = useNavigate();
	const [phoneData, setPhoneData] = useState(INITIAL_PHONE_DATA);
	const [isLoading, setIsLoading] = useState(false);

	const business = useMemo(
		() => JSON.parse(localStorage.getItem("businessData") || "null"),
		[]
	);
	const token = useMemo(() => localStorage.getItem("token"), []);

	useEffect(() => {
		if (token && business) {
			navigate(`/?id=${business.id ?? 7}`);
		}
	}, [token, business, navigate]);

	const handlePhoneChange = useCallback((phone: string, countryData: any) => {
		const parsedPhone = parsePhoneNumberFromString(phone);
		setPhoneData({
			number: phone,
			countryCode: countryData.country.iso2,
			dialCode: `+${countryData.country.dialCode}`,
			inputValue: phone.replace(`+${countryData.country.dialCode}`, "").trim(),
			isValid: parsedPhone ? parsedPhone.isValid() : false,
		});
	}, []);

	const handleDone = useCallback(async () => {
		setIsLoading(true);
		try {
			const res = await sendOtp({
				country_code: phoneData.dialCode,
				iso_code: phoneData.countryCode.toUpperCase(),
				phone_number: phoneData.inputValue,
			});

			if (res.data.status === 1) {
				toast.success(res.data.message);
				localStorage.setItem("phone_data", JSON.stringify(phoneData));
				navigate("/verification", {
					state: { phone: phoneData, otp: res.data.otp },
				});
			} else {
				toast.error(res.data.message);
			}
		} catch (err: any) {
			toast.error(err.response?.data?.message || "An error occurred.");
		} finally {
			setIsLoading(false);
		}
	}, [phoneData, navigate]);

	const handleGoogleLogin = useCallback(async () => {
		try {
			const result = await signInWithGoogle(auth, googleProvider);
			const user = result.user;
			if (!user?.email) return toast("Email not found in Google account.");

			const { data } = await axios.post(
				API_URL,
				{
					email_id: user.email,
					thirdparty_id: user.uid,
					user_role: "Customer",
				},
				{ headers: { "Content-Type": "application/json" } }
			);

			if (data.status === 1) {
				toast.success("Login successful!");
				navigate("/review");
			} else if (data.message === "Email not found.") {
				const { token } = await user.getIdTokenResult();

				localStorage.setItem("token", token);

				localStorage.setItem(
					"userData",
					JSON.stringify({
						email_id: user.email,
						fullname: user.displayName,
						phone_number: user.phoneNumber,
					})
				);
				localStorage.setItem("google_user", JSON.stringify(user));
				navigate("/review");
				// navigate("/personal_info");
			}
		} catch (error: any) {
			toast.error(error.message || "Google login failed. Please try again.");
		}
	}, [navigate]);

	return (
		<div className="container mx-auto my-10">
			<div className="flex items-center justify-end">
				<button
					onClick={() => navigate(-1 || `/${business?.id}`)}
					className="rounded-full w-7 h-7 bg-gray-100 flex items-center justify-center"
				>
					<IoClose className="cursor-pointer size-5" />
				</button>
			</div>
			<div className="pb-5">
				<div className="mb-8">
					<p className="font-medium text-2xl sm:text-3xl pb-2">
						Log in or sign up
					</p>
					<p className="text-gray-500 sm:text-sm text-xs heading-font">
						Log in or sign up to complete your booking
					</p>
				</div>
				<form onSubmit={(e) => e.preventDefault()}>
					<div className="flex items-center gap-x-2 mb-10">
						<PhoneInput
							defaultCountry="ng"
							value={phoneData.number}
							onChange={handlePhoneChange}
							className="block w-full rounded-xl bg-white shadow-sm"
							required
						/>
					</div>
					<button
						type="button"
						className={`rounded-2xl py-4 w-full font-medium sm:text-lg flex items-center justify-center ${
							phoneData.isValid
								? "bg-[#8877D8] text-white"
								: "bg-gray-300 text-gray-400 cursor-not-allowed"
						}`}
						onClick={handleDone}
						disabled={!phoneData.isValid}
					>
						{isLoading ? (
							<ThreeDots height="28" width="50" color="#fff" visible={true} />
						) : (
							"Continue"
						)}
					</button>
				</form>
				<div className="flex items-center w-full my-4">
					<div className="flex-grow border-t border-gray-300" />
					<span className="mx-4 text-gray-500 my-5">OR</span>
					<div className="flex-grow border-t border-gray-300" />
				</div>
				<button
					onClick={handleGoogleLogin}
					className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
				>
					<img
						src={googleicon}
						alt="Google Logo"
						className="w-5 h-5"
					/>
					<span className="font-medium text-gray-700">
						Continue with Google
					</span>
				</button>
			</div>
		</div>
	);
}

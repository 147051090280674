import React from "react";
import Progress from "react-progressbar";
import { BusinessData, BusinessService } from "../../types/business";
import { ServiceList } from "./ServiceList";

interface TabContentProps {
	tab: number;
	data: BusinessData;
	onServiceSelect: (service: BusinessService) => void;
	onImageClick: (image: string) => void;
}

export const TabContent: React.FC<TabContentProps> = ({
	tab,
	data,
	onServiceSelect,
	onImageClick,
}) => {
	if (tab === 1) {
		return (
			<div className="mt-4">
				<div className="grid grid-cols-12 gap-1.5">
					{data.portfolio_images?.map((item, index) => (
						<div className="col-span-4 sm:h-36 h-28" key={index}>
							<img
								src={item.portfolio_image}
								className="rounded-lg object-cover h-full w-full cursor-pointer"
								alt="portfolio_image"
								onClick={() => onImageClick(item.portfolio_image)}
							/>
						</div>
					))}
				</div>
			</div>
		);
	}

	if (tab === 2) {
		return (
			<div>
				{[
					{ number: 5, text: "five" },
					{ number: 4, text: "four" },
					{ number: 3, text: "three" },
					{ number: 2, text: "two" },
					{ number: 1, text: "one" },
				].map((stars) => (
					<div key={stars.text} className="flex items-center container mt-4">
						<p>{stars.number}</p>
						<div className="w-full ms-4 me-10">
							<Progress
								completed={
									Number(
										data[`${stars.text}_star_reviews` as keyof BusinessData]
									) * 10
								}
							/>
						</div>
						<p>
							{Number(data[`${stars.text}_star_reviews` as keyof BusinessData])}
						</p>
					</div>
				))}
			</div>
		);
	}

	return (
		<ServiceList
			services={data.business_service}
			onServiceSelect={onServiceSelect}
		/>
	);
};

import { useState } from "react";
import { BusinessData } from "../types/business";
import { getBussiness } from "../Services/ApiService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useBusinessData = (businessId: string | null) => {
	const navigate = useNavigate();
	const [data, setData] = useState<BusinessData>({} as BusinessData);
	const [loader, setLoader] = useState(false);

	const getBusinessData = async () => {
		if (!businessId) {
			navigate("/404_error");
			return;
		}

		setLoader(true);
		try {
			const response = await getBussiness({ business_id: businessId });
			if (response.data.status === 1) {
				setData(response.data.data);
				localStorage.setItem(
					"businessData",
					JSON.stringify(response.data.data)
				);
			} else {
				toast.error(response.data.message);
			}
		} catch (err: any) {
			const status = err.response?.status;
			if (status === 500) {
				navigate("/no_data_found");
			}
			if (status === 422) {
				toast.error(err.response?.data?.errors[0]?.msg);
			} else {
				toast.error(err.response?.data?.Message || "An error occurred.");
			}
		} finally {
			setLoader(false);
		}
	};

	return {
		data,
		loader,
		getBusinessData,
	};
};

import React from "react";
import { Service } from "../../types/service";

interface AddonServicesProps {
	addonServices?: Service[];
	onSelect: (addon: Service) => void;
	formatDuration: (duration: string) => string;
}

export const AddonServices: React.FC<AddonServicesProps> = ({
	addonServices,
	onSelect,
	formatDuration,
}) => {
	if (!addonServices?.length) return null;

	return (
		<div className="pb-5">
			<div className="mt-7">
				<p className="heading-font font-medium sm:text-lg text-base">Add-ons</p>
				{addonServices.map((item, index) => (
					<div className="py-3 border-b last:border-b-0 mt-3" key={index}>
						<div className="flex items-center justify-between">
							<div className="sm:text-sm text-xs font-normal">
								<p className="text-[#1D2939]">{item.service_name}</p>
								<p className="text-[#667085] pt-1">
									{formatDuration(item.duration)}
								</p>
							</div>
							<div className="flex items-center gap-x-2">
								<p className="sm:text-sm text-xs font-medium text-[#667085]">
									₦{Number(item.price).toLocaleString()}
								</p>
								<input
									type="checkbox"
									className="w-6 h-6 custom-checkbox rounded-lg"
									onChange={() => onSelect(item)}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

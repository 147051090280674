import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import payment from "../../assets/images/payment.png";
import distance from "../../assets/images/distance.png";
import distance1 from "../../assets/images/distance_gray.png";
import line from "../../assets/images/Line.png";
import p6 from "../../assets/images/UsersThree.png";
import Dialog from "../../Components/Dialog/Dialog";
import { IoClose } from "react-icons/io5";
import { FiChevronLeft } from "react-icons/fi";
import clock from "../../assets/images/Time.png";
import calendar from "../../assets/images/Calendar.png";
import edit from "../../assets/images/edit.png";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { components, OptionProps } from "react-select";
import "react-loading-skeleton/dist/skeleton.css";
import { completePaymetApi } from "../../Services/ApiService";
import { useTime } from "../../Components/TimeContext/TimeContext";
import { ThreeDots } from "react-loader-spinner";
import { isIOS } from "react-device-detect";
import { ServiceDetails } from "../../types/service";
import { useServiceDetails } from "../../hooks/useServiceDetails";

interface Address {
	label: string;
	value: {
		place_id: string;
	};
}

export default function CheckOutPage() {
	const navigate = useNavigate();

	const { formattedTime, setTime } = useTime();
	const [isPaying, setIsPaying] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<Record<string, any>>({});
	const [selectedService, setSelectedService] = useState<Record<string, any>>(
		{}
	);
	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [address, setAddress] = useState<any>();
	const [discount, setDiscount] = useState<number | undefined>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [totalPrice, setTotalPrice] = useState<number>(0);
	const [price, setPrice] = useState<number>(0);
	const [currentAddress, setCurrentAddress] = useState<string | undefined>();
	const [selectedButler, setSelectedButler] = useState<any>(undefined);
	const [userData, setUserData] = useState<Record<string, any>>({});
	const date: any = localStorage.getItem("date");

	useEffect(() => {
		const storedAddress = localStorage.getItem("address");
		const storedServiceDetails = localStorage.getItem("serviceDetails");
		const storedTime = JSON.parse(localStorage.getItem("selectedTime") || "{}");
		const storedAddon = localStorage.getItem("selectedAddon");
		const userData = JSON.parse(localStorage.getItem("userData") || "{}");
		const storedButlerData = JSON.parse(
			localStorage.getItem("butlerId") || "{}"
		);
		const storedService = localStorage.getItem("mainService");
		const businessData = JSON.parse(
			localStorage.getItem("businessData") || "{}"
		);
		const token = localStorage.getItem("token");
		const bookingId = localStorage.getItem("bookingId");
		window.scrollTo({
			top: document.documentElement.scrollHeight,
			behavior: "smooth",
		});

		if (bookingId) {
			navigate(`/?id=${businessData.id}`);
		}
		if (!token) {
			navigate("/login");
		}
		if (businessData) {
			setData(businessData);
		}
		if (storedService) {
			setSelectedService(JSON.parse(storedService));
		}
		if (userData) {
			setUserData(userData);
		}
		if (storedButlerData) {
			setSelectedButler(storedButlerData);
		}

		if (storedAddon) {
			setSelectedItems(JSON.parse(storedAddon));
		}

		if (storedTime) {
			setTime(storedTime.time);
		}

		if (storedServiceDetails && storedService) {
			const { totalPrice }: ServiceDetails = JSON.parse(storedServiceDetails);
			const data = JSON.parse(storedService);
			const newPrice =
				data.home_service_price !== null
					? data.home_service_price
					: data.walk_in_price !== null
					? data.walk_in_price
					: 0;
			setPrice(newPrice);
			setTotalPrice(totalPrice + newPrice);
		}

		if (storedAddress) {
			setAddress(storedAddress);
		}
	}, []);

	const { handleServiceBack } = useServiceDetails(selectedService.business_id);

	const handleBooking = () => {
		setIsLoading(true);
		localStorage.setItem(
			"address",
			address?.label || currentAddress || "Fetching address..."
		);
		const params = {
			amount: totalPrice,
			email: userData.email_id,
			type: "web",
		};

		completePaymetApi(params)
			.then((res) => {
				const url = res.data.data.data.authorization_url;
				window.location.href = url;
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	const handleAddressChange = async (value: any) => {
		setAddress(value);
		setIsOpen(false);

		if (value) {
			const placeId = value.value.place_id;

			const service = new window.google.maps.places.PlacesService(
				document.createElement("div")
			);

			service.getDetails({ placeId }, (place: any, status: any) => {
				if (status === window.google.maps.places.PlacesServiceStatus.OK) {
					const addressComponents = place.address_components;
					const postalCodeComponent = addressComponents.find((component: any) =>
						component.types.includes("postal_code")
					);
					const obj = {
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng(),
					};

					localStorage.setItem("latlon", JSON.stringify(obj));

					if (postalCodeComponent) {
						console.log(
							"postalCodeComponent.long_name ???",
							postalCodeComponent.long_name
						);
					} else {
						console.log("Postal code not found");
					}
				} else {
					console.error("Error fetching place details:", status);
				}
			});
		}
	};

	const CustomOption = (props: OptionProps<Address, false>) => (
		<components.Option {...props}>
			<div className="flex items-center">
				<img
					src={distance1}
					alt="location icon"
					style={{ width: "1rem", marginRight: "10px" }}
				/>
				{props.data.label}
			</div>
		</components.Option>
	);

	const formatDuration = (duration: string | undefined) => {
		if (!duration) {
			return "N/A";
		}
		const [hours, minutes] = duration.split(":").map(Number);

		const result: string[] = [];

		if (hours > 0) {
			result.push(`${hours} hour${hours > 1 ? "s" : ""}`);
		}
		result.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
		return result.join(" ");
	};

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);

		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
		} as const;
		const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
			date
		);

		const day = date.getDate();
		const ordinalSuffix = getOrdinalSuffix(day);

		return formattedDate.replace(day.toString(), `${day}${ordinalSuffix}`);
	};

	const getOrdinalSuffix = (day: number): string => {
		if (day > 3 && day < 21) return "th";
		switch (day % 10) {
			case 1:
				return "st";
			case 2:
				return "nd";
			case 3:
				return "rd";
			default:
				return "th";
		}
	};

	return (
		<div>
			<div className="sticky top-0 z-10 bg-white ">
				<div className="border-b pb-4 sm:w-[30rem] sm:mx-auto">
					<div className="container mx-auto ">
						<div className="px-4 py-3 bg-[#E7E4F7] rounded-2xl flex justify-between items-center">
							<div className="flex items-center gap-x-2 sm:w-1/2">
								<img src={logo} alt="Beauty Butler" className="w-12 h-12" />
								<div>
									<p className="sm:text-sm text-xs font-normal heading-font">
										Beauty Butler
									</p>
									<p className="sm:text-xs text-[10px] text-[#667085] font-normal">
										Beauty treatments direct to your doorstep
									</p>
								</div>
							</div>
							<a
								className="bg-[#8877D8] text-white rounded-lg py-2 sm:px-4 sm:w-auto w-44 font-medium text-xs text-center ms-2"
								href={`${
									isIOS
										? "https://apps.apple.com/app/id6608976314"
										: "https://play.google.com/store/apps/details?id=com.beautybutler.use"
								}`}
								target="_blank"
							>
								Download App
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-4 container mx-auto">
				<div className="rounded-2xl flex justify-between items-center gap-x-2 mb-2 ">
					<div
						className="flex items-center justify-center cursor-pointer rounded-full w-7 h-7 bg-gray-100"
						onClick={handleServiceBack}
					>
						<FiChevronLeft className="w-4" />
					</div>
					<p className="sm:text-xl text-lg text-[#1D2939] font-medium heading-font">
						Checkout
					</p>
					<p></p>
				</div>
				<div className="flex items-center gap-x-3.5 mb-3.5 py-4">
					<img
						src={
							selectedService?.service_images &&
							selectedService?.service_images[0]?.service_image
						}
						className="h-[70px] w-[77px] object-cover rounded-xl"
						alt=""
					/>
					<div className="sm:text-sm text-xs font-normal ">
						<p className="text-[#8877D8] pb-0.5 heading-font sm:text-base text-sm">
							{data.business_name}
						</p>
						<p className="text-[#1D2939] pb-0.5 sm:text-sm text-xs">
							{selectedService?.service_name}
						</p>
						<div className="flex items-center mt-0.5">
							<div className="flex items-center gap-x-1.5">
								<img src={calendar} className="h-4 w-4" alt="" />
								<p className="text-[#1D2939] sm:text-sm text-xs">
									{formatDate(date)}
								</p>
							</div>
							<img src={line} className="h-3 sm:px-3 px-1.5" alt="" />
							<div className="flex items-center gap-x-1.5  ">
								<img src={clock} className="h-4 w-4" alt="" />
								<p className="text-[#1D2939] sm:text-sm text-xs">
									{formattedTime}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="my-3.5">
					<div className="py-4">
						<p className="font-medium pb-3 heading-font sm:text-base text-sm">
							Butler
						</p>
						<div className="flex items-center gap-x-4 rounded-2xl p-3 border border-gray-200">
							<img
								src={
									selectedButler?.profile_image
										? selectedButler?.profile_image
										: p6
								}
								className={` ${
									selectedButler?.profile_image
										? "w-10 h-10 rounded-full"
										: "w-8 h-8"
								}  `}
								alt=""
							/>
							<p className="font-medium text-[#1D2939] sm:text-base text-sm">
								{selectedButler?.fullname || "Any Butler"}
							</p>
						</div>
					</div>
				</div>
				<div>
					<div className="my-4">
						<p className="text-[#1D2939] font-medium pt-3 heading-font sm:text-base text-sm">
							OverView
						</p>
						<div className="rounded-2xl border border-gray-200 px-3 pb-3 mt-3">
							<div className="flex justify-between items-center gap-x-2 border-b py-4">
								<div className="sm:sm:text-sm text-xs ">
									<p className="pb-1 text-[#1D2939]">
										{selectedService?.service_name}
									</p>
									<p className="text-[#667085]">
										{formatDuration(selectedService?.duration)}
									</p>
								</div>
								<p className="text-[#667085] sm:sm:text-sm  text-xs">
									₦{Number(price).toLocaleString()}
								</p>
							</div>
							{selectedItems?.map((item, index) => (
								<div
									className="flex justify-between items-center gap-x-2 border-b py-4"
									key={index}
								>
									<div className="sm:sm:text-sm text-xs">
										<p className="pb-1 text-[#1D2939]">{item?.service_name}</p>
										<p className="text-[#667085]">
											{formatDuration(item?.duration)}
										</p>
									</div>
									<p className="text-[#667085] sm:sm:text-sm text-xs">
										₦{Number(price).toLocaleString()}
									</p>
								</div>
							))}
							<div className="flex items-center justify-between gap-x-2 font-medium my-3 sm:text-base text-sm">
								<p>Total</p>
								<p>₦{Number(totalPrice).toLocaleString()}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-8">
					<div className="my-4">
						<p className="text-[#1D2939] font-medium pt-3 heading-font sm:text-base text-sm">
							Contact Information
						</p>
						<div className="rounded-2xl border border-gray-200 text-[#1D2939] px-3 mt-3">
							<div className="flex justify-between items-center gap-x-2 border-b py-4">
								<div className="sm:text-sm text-xs">
									<p className="pb-1 ">Full Name</p>
								</div>
								<p className="sm:text-sm text-xs">{userData.fullname}</p>
							</div>
							<div className="flex justify-between items-center gap-x-2 border-b py-4">
								<div className="sm:text-sm text-xs">
									<p className="pb-1">Email Address</p>
								</div>
								<p className="sm:text-sm text-xs">{userData.email_id}</p>
							</div>
							<div className="flex justify-between items-center gap-x-2 py-4">
								<div className="sm:text-sm text-xs">
									<p className="pb-1 ">Phone Number</p>
								</div>
								<p className="sm:text-sm text-xs">
									{userData.country_code}
									{userData.phone_number}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-8">
					<div className="my-4">
						<p className="text-[#1D2939] font-medium pt-3 heading-font sm:text-base text-sm">
							Address
						</p>
						<div className="rounded-2xl border border-gray-200 px-3 mt-3">
							<div className="flex justify-between items-center gap-x-2 py-4">
								<div className="flex items-center gap-x-1 w-11/12">
									<img src={distance} alt="location icon" className="w-6" />

									<p className="truncate w-full sm:text-base text-sm">
										{address?.label ||
											address ||
											currentAddress ||
											"Fetching address..."}
									</p>
								</div>
								<img
									src={edit}
									className="w-4 cursor-pointer"
									alt=""
									onClick={() => setIsOpen(true)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-8">
					<div className="mb-8">
						<p className="text-[#1D2939] font-medium pt-3 heading-font sm:text-base text-sm">
							Discount code
						</p>
						<div className="rounded-2xl border border-gray-200 px-3 mt-3">
							<div className="flex justify-between items-center gap-x-2 py-2">
								<input
									type="text"
									className="pb-1 text-[#1D2939] truncate w-full outline-none sm:text-base text-sm"
									placeholder="Enter discount code (optional)"
									name="discount"
									onChange={(e: any) => setDiscount(e.currentTarget.value)}
								/>
								<button
									className={`${
										discount
											? "bg-[#8877D8] text-white "
											: "bg-[#8877D870] text-white "
									} rounded-xl pt-2 pb-2.5 px-5 font-medium align-middle flex items-start justify-center sm:text-base text-sm`}
								>
									Apply
								</button>
							</div>
						</div>
					</div>
					<div className="mb-7">
						<p className="text-[#1D2939] font-medium pt-3 heading-font sm:text-base text-sm">
							Payment method
						</p>
						<div className="mt-3">
							<div
								className="flex justify-between items-center gap-x-2 py-2 cursor-pointer"
								onClick={() => setIsPaying("yes")}
							>
								<div className="flex items-center gap-x-2">
									<img src={payment} className="w-10 h-10" alt="" />
									<p className="text-[#1D2939] sm:text-base text-sm">
										Pay with Paystack
									</p>
								</div>

								<input
									type="radio"
									name="radio"
									value="yes"
									checked={isPaying === "yes"}
									onChange={(e) => setIsPaying(e.target.value)}
									className="form-radio"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="mb-48">
					<div>
						<p className="text-[#1D2939] font-medium heading-font">
							Cancellation Policy
						</p>
						<div className="sm:text-sm text-xs text-[#667085]">
							<p className="pb-3">
								We totally get it – life happens! But to keep the beauty vibes
								flowing, let's make cancellations a breeze. Just give us a
								heads-up at least 24 hours in advance to avoid being charged 80%
								of the service price.
							</p>
							<p>
								Sadly, if you're a no-show, it's a full 100% charge. Let's make
								every appointment a glam affair, shall we? 💅✨
							</p>
						</div>
					</div>
				</div>
				<div className="mt-2 py-4 fixed inset-x-0 bottom-0 z-10 bg-white border-t sm:w-[30rem] sm:mx-auto">
					<div className="mx-auto container">
						<button
							className={`rounded-2xl py-4 w-full font-medium sm:text-lg flex items-center justify-center ${
								!isPaying && isLoading
									? "bg-gray-300 cursor-not-allowed"
									: "bg-[#8877D8] text-white"
							} ${
								isPaying
									? "bg-[#8877D8] text-white"
									: "bg-gray-300 text-gray-400 cursor-not-allowed"
							}`}
							onClick={handleBooking}
							disabled={isLoading}
						>
							{isLoading ? (
								<ThreeDots height="28" width="50" color="#fff" visible={true} />
							) : (
								"Complete Booking"
							)}
						</button>
					</div>
					<div className="text-center sm:text-[0.875rem] text-[0.75rem] text-gray-400 leading-6 mt-3">
						Booking confirms acceptance of our{" "}
						<a
							className="text-[#8877D8] underline"
							href="https://beautybutler.notion.site/Booking-Terms-129e18c6755d8098983ad21922ce0b9c?pvs=4"
							target="_blank"
						>
							Booking Terms{" "}
						</a>
						For data practices, see our{" "}
						<a
							className="text-[#8877D8] underline"
							href="https://beautybutler.notion.site/Terms-and-Conditions-129e18c6755d805c862ee8f446c2c52d?pvs=4"
							target="_blank"
						>
							Terms{" "}
						</a>
						<span className="text-gray-400 no-underline">&</span>{" "}
						<a
							className="text-[#8877D8] underline"
							href="https://beautybutler.notion.site/Privacy-Policy-129e18c6755d8003a1d7ff7c013616a8?pvs=4"
							target="_blank"
						>
							Privacy Policy.
						</a>
					</div>
				</div>
			</div>

			<Dialog
				open={isOpen}
				onClose={() => setIsOpen(false)}
				className="relative z-50"
				size="lg"
			>
				<div className="fixed inset-0 flex w-screen items-center justify-center p-4 container mx-5">
					<Dialog.Panel className="max-w-lg border bg-white rounded-xl shadow-lg">
						<Dialog.Description>
							<div className="flex justify-end items-center">
								<div className="rounded-full w-7 h-7 bg-gray-100 flex items-center justify-between">
									<IoClose
										className="cursor-pointer w-7 size-6 text-[#667085]"
										onClick={() => {
											setIsOpen(false);
										}}
									/>
								</div>
							</div>

							<div className="pb-5 sm:px-5 px-2">
								<p className="text-center font-medium sm:text-xl text-lg pb-4 heading-font">
									Address
								</p>
								<div className="w-full relative flex items-center py-2 border border-gray-300 rounded-[14px] my-2 pe-4">
									<div className="w-full flex items-center">
										<img
											src={distance}
											alt="location icon"
											className="ms-3 w-6"
										/>
										<GooglePlacesAutocomplete
											apiKey="AIzaSyDuqKaM8WQIZNWbdu2VAVaYrr2I7ZjSpOM"
											selectProps={{
												value: address,
												onChange: handleAddressChange,
												placeholder: "Enter a new address",
												components: {
													Option: CustomOption,
												},
												styles: {
													control: (provided) => ({
														...provided,
														width: "100%",
														border: "none",
														boxShadow: "none",
													}),
													input: (provided) => ({
														...provided,
														width: "100%",
														border: "none",
														boxShadow: "none",
													}),
													menu: (provided) => ({
														...provided,
														width: "calc(100% + 2.7rem)",
														marginLeft: "-2rem",
														boxShadow: "none",
														border: "1px solid rgb(209 213 219)",
													}),
													indicatorsContainer: (provided) => ({
														...provided,
														display: "none",
													}),
													indicatorSeparator: (provided) => ({
														...provided,
														display: "none",
													}),
												},
											}}
										/>
									</div>
								</div>

								<div className="py-5 border-b">
									<p className="font-medium sm:sm:text-sm text-xs pb-1">
										Use your current location
									</p>
									<p
										className="text-gray-600 font-normal sm:sm:text-sm text-xs cursor-pointer"
										onClick={() => {
											setAddress(currentAddress);
											setIsOpen(false);
										}}
									>
										{currentAddress}
									</p>
								</div>
							</div>
						</Dialog.Description>
					</Dialog.Panel>
				</div>
			</Dialog>
		</div>
	);
}

import { initializeApp, FirebaseApp } from "firebase/app";
import {
	getAuth,
	Auth,
	GoogleAuthProvider,
	signInWithPopup,
	signOut,
	onAuthStateChanged,
	User,
	signInWithRedirect,
	AuthProvider,
} from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyAB1JAkXR9Wspss-xzd78jvJwFmbhw0oyw",
	authDomain: "mybeautybutler-auth.firebaseapp.com",
	projectId: "mybeautybutler-auth",
	storageBucket: "mybeautybutler-auth.firebasestorage.app",
	messagingSenderId: "876518818655",
	appId: "1:876518818655:web:5f0f5a5639c32b5fbbecea",
	measurementId: "G-PTQ3F8L94H",
};

// Initialize Firebase with error handling
let app: FirebaseApp;
let auth: Auth;

try {
	app = initializeApp(firebaseConfig);
	auth = getAuth(app);
} catch (error) {
	console.error("Error initializing Firebase:", error);
	throw new Error("Failed to initialize Firebase. Check your configuration.");
}

// Configure Google Auth Provider with proper scope and settings
const googleProvider = new GoogleAuthProvider();

// Add multiple scopes if needed
const requiredScopes = ["email", "profile"];

requiredScopes.forEach((scope) => {
	googleProvider.addScope(scope);
});

googleProvider.setCustomParameters({
	prompt: "select_account",
	access_type: "offline", // Request refresh token
});

// Auth state observer
const subscribeToAuthChanges = (callback: (user: User | null) => void) => {
	return onAuthStateChanged(auth, callback);
};

// Sign out utility
const signOutUser = async (): Promise<void> => {
	try {
		await signOut(auth);
	} catch (error) {
		console.error("Error signing out:", error);
		throw error;
	}
};

// Type for auth error
interface AuthError extends Error {
	code?: string;
	message: string;
}

// Utility function to handle Firebase auth errors
const handleAuthError = (error: AuthError): string => {
	switch (error.code) {
		case "auth/popup-closed-by-user":
			return "Sign-in popup was closed before completing.";
		case "auth/popup-blocked":
			return "Sign-in popup was blocked by the browser.";
		case "auth/cancelled-popup-request":
			return "Multiple popup requests were cancelled.";
		case "auth/account-exists-with-different-credential":
			return "An account already exists with the same email address but different sign-in credentials.";
		default:
			return (
				error.message || "An unknown error occurred during authentication."
			);
	}
};

// Enhanced sign in with Google function with proper error handling
const signInWithGoogle = async (auth: Auth, googleProvider: AuthProvider) => {
	try {
		const result = await signInWithPopup(auth, googleProvider);
		return result;
	} catch (error) {
		const authError = error as AuthError;
		const errorMessage = handleAuthError(authError);
		throw new Error(errorMessage);
	}
};

export {
	auth,
	googleProvider,
	signInWithPopup,
	signInWithGoogle,
	signOutUser,
	subscribeToAuthChanges,
	handleAuthError,
};

import React from "react";
import errorImag from "../../assets/images/404Page1.png";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <img src={errorImag} alt="" />
        <p className="text-2xl md:text-3xl font-medium text-[#8877D8] mt-4">
          Oops! Page not found.
        </p>
        <p className="text-gray-500 mt-2">
          The page you’re looking for doesn’t exist.
        </p>
      </div>
    </div>
  );
};

export default NotFound;

export const formatDuration = (duration: string | undefined): string => {
	if (!duration || typeof duration !== "string") {
		return "N/A";
	}

	const [hours, minutes] = duration.split(":").map(Number);
	const result: string[] = [];

	if (hours > 0) {
		result.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
	}

	if (minutes > 0) {
		result.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
	}

	return result.join(" ");
};

export const formatTotalTime = (totalMinutes: number): string => {
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;

	let result: string[] = [];
	if (hours > 0) {
		result.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
	}
	if (minutes > 0) {
		result.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
	}

	return result.join(", ");
};

export const convertToMinutes = (timeString: string): number => {
	if (!timeString) return 0;

	const [hours, minutes, seconds] = timeString.split(":").map(Number);
	return hours * 60 + minutes + seconds / 60;
};

export const formatPrice = (price: number): string => {
	return Number(price).toLocaleString();
};

export const getStoredData = <T>(key: string, defaultValue: T): T => {
	try {
		const storedValue = localStorage.getItem(key);
		return storedValue ? JSON.parse(storedValue) : defaultValue;
	} catch (error) {
		console.error(`Error reading ${key} from localStorage:`, error);
		return defaultValue;
	}
};

export const setStoredData = <T>(key: string, value: T): void => {
	try {
		localStorage.setItem(key, JSON.stringify(value));
	} catch (error) {
		console.error(`Error writing ${key} to localStorage:`, error);
	}
};

export const removeStoredData = (keys: string[]): void => {
	keys.forEach((key) => {
		try {
			localStorage.removeItem(key);
		} catch (error) {
			console.error(`Error removing ${key} from localStorage:`, error);
		}
	});
};

export const isValidService = (service: any): boolean => {
	return !!(
		service &&
		typeof service === "object" &&
		"service_name" in service &&
		"price" in service &&
		"duration" in service
	);
};

import React, { useMemo, useState } from "react";
import { BusinessService } from "../../types/service";
import line from "../../assets/images/Line.png";
import car from "../../assets/images/car-icon.png";
import { convertToMinutes } from "../../utils/formatters";

interface ServiceDescriptionProps {
	service: BusinessService | null;
	formatDuration: (duration: string) => string;
}

export const ServiceDescription: React.FC<ServiceDescriptionProps> = ({
	service,
	formatDuration,
}) => {
	const [readFlag, setReadFlag] = useState(false);
	const limit = 150;

	const displayPrice = useMemo(() => {
		return service?.home_service_price !== null
			? service?.home_service_price
			: service.walk_in_price !== null
			? service.walk_in_price
			: "0";
	}, [service]);

	const displayServiceType = useMemo(() => {
		return service?.home_service_price !== null &&
			service?.walk_in_price !== null
			? "both"
			: service.walk_in_price !== null
			? "walk_in"
			: "home_service";
	}, [service]);
	if (!service) return null;

	return (
		<div className="container mx-auto">
			<div className="mt-5">
				<p className="text-[#1D2939] font-medium heading-font sm:text-xl text-lg">
					{service.service_name}
				</p>
				<p className="mt-2 text-[#667085] text-xs sm:text-sm">
					{readFlag
						? service.description
						: `${service.description?.slice(0, limit)}`}
					{service.description?.length > limit && (
						<button
							onClick={() => setReadFlag(!readFlag)}
							className="text-[#8877D8] font-medium"
						>
							{readFlag ? "View Less" : "... View More"}
						</button>
					)}
				</p>

				<div className="mt-2 flex items-center gap-x-2 sm:text-sm text-xs pb-5">
					<p className="text-[#667085]">{formatDuration(service.duration)}</p>

					{/* <p className="text-[#667085]">
						₦{Number(displayPrice).toLocaleString()}
					</p> */}
					<img src={line} alt="" className="h-5" />
					<div className="text-[#667085] flex gap-x-2 items-center">
						<img src={car} alt="" className="h-3.5" />
						<p> {formatDuration(service.duration)}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

// export const BASE_URL = "http://13.247.98.141:9000";
export const BASE_URL = "https://api.mybeautybutler.com";
// export const BASE_URL = "http://98.81.206.68:9000";

export const GET_BUSINESS = BASE_URL + "/customer/business/detail";
export const GET_BUTLER = BASE_URL + "/customer/butler";
export const SEND_OTP = BASE_URL + "/customer/sendotp";
export const VERIFY_OTP = BASE_URL + "/customer/verifyotp";
export const FINAL_STEP = BASE_URL + "/customer/addEmail";
export const MORE_TO_DISCOVER = BASE_URL + "/customer/moreToDiscover";
export const TIme_SLOT = BASE_URL + "/customer/timeSlot";

export const COMPLETE_BOOKING_API = BASE_URL + "/payment/initial";
export const GET_BOOKING_DETAILS = BASE_URL + "/payment/verify";
export const ADD_BOOKING = BASE_URL + "/customer/booking";

import React from "react";
import "./App.css";
import RoutData from "../src/router/router";
import { TimeProvider } from "./Components/TimeContext/TimeContext";
import { DateProvider } from "./Components/DateProvider/DateProvider";

function App() {
  return (
    <>
      <TimeProvider>
        <DateProvider>
          <RoutData />
        </DateProvider>
      </TimeProvider>
    </>
  );
}

export default App;

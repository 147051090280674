import React from "react";
import Slider from "react-slick";
import { ServiceImage } from "../../types/service";

interface ServiceImagesProps {
	images?: ServiceImage[];
}

export const ServiceImages: React.FC<ServiceImagesProps> = ({ images }) => {
	const settings = {
		arrows: false,
		speed: 500,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	};

	if (!images?.length) return null;

	if (images.length === 1) {
		return (
			<div>
				<img
					src={images[0].service_image}
					className="object-cover w-full h-72"
					alt={images[0].service_name}
				/>
			</div>
		);
	}

	return (
		<Slider {...settings}>
			{images.map((item, index) => (
				<div key={index}>
					<img
						src={item.service_image}
						className="object-cover w-full h-72"
						alt={item.service_name}
					/>
				</div>
			))}
		</Slider>
	);
};

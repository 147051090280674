import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const LoadingSkeleton: React.FC = () => (
	<SkeletonTheme
		highlightColor="#ffffff30"
		borderRadius="0.5rem"
		duration={1.5}
	>
		<div className="py-4 container mx-auto bg-white z-10">
			<Skeleton height={30} />
		</div>
		<div className="container mx-auto">
			<Skeleton height={200} />
			<Skeleton height={50} className="mt-10" />
			<Skeleton height={30} className="mt-5" />
			<Skeleton height={50} className="mt-4 mb-5" />
			<Skeleton height={500} className="mb-5" />
		</div>
	</SkeletonTheme>
);

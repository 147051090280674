import React from "react";
import { FiChevronLeft, FiHeart, FiShare } from "react-icons/fi";

interface ServiceHeaderProps {
	onBack: () => void;
}

export const ServiceHeader: React.FC<ServiceHeaderProps> = ({ onBack }) => {
	return (
		<div className="relative w-full h-full">
			<div className="flex items-center justify-between w-full absolute top-10 px-5 z-10">
				<div
					className="flex items-center justify-center cursor-pointer rounded-full w-7 h-7 bg-gray-100"
					onClick={onBack}
				>
					<FiChevronLeft className="w-4" />
				</div>
				<div className="flex flex-row gap-2">
					<div className="flex items-center justify-center cursor-pointer rounded-full w-7 h-7 bg-gray-100">
						<FiShare className="w-4" />
					</div>
					
				</div>
			</div>
		</div>
	);
};

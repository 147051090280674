import React, { useMemo, useState } from "react";
import { FaStar } from "react-icons/fa";
import { BusinessData } from "../../types/business";
import line from "../../assets/images/Line.png";
import homeservice from "../../assets/images/homeservice.png";
import walkin from "../../assets/images/walk-in.png";
import distance1 from "../../assets/images/distance_gray.png";

interface BusinessInfoProps {
	data: BusinessData;
	currentTab: number;
	onTabChange: (tab: number) => void;
}

export const BusinessInfo: React.FC<BusinessInfoProps> = ({
	currentTab,
	data,
	onTabChange,
}) => {
	const [readFlag, setReadFlag] = useState(false);
	const limit = 150;

	const displayServiceType = useMemo(() => {
		return data.walk_in && data.home_service
			? "both"
			: data.walk_in
			? "walk_in"
			: data.home_service
			? "home_service"
			: null;
	}, [data.walk_in, data.home_service]);

	const toggleExpanded = () => setReadFlag(!readFlag);

	const tabButtons = [
		{ id: 0, label: "Services" },
		{ id: 1, label: "Pictures" },
		{ id: 2, label: "Reviews" },
	];

	return (
		<div className="mx-auto">
			<p className="sm:text-xl text-lg font-medium pb-2 heading-font">
				{data.business_name}
			</p>
			<div className="mb-4">
				<div className="text-[#667085] text-sm sm:text-sm">
					{readFlag ? data.bio : `${data?.bio?.slice(0, limit)}`}
					{data.bio?.length > limit && (
						<button
							onClick={toggleExpanded}
							className="text-[#8877D8] font-medium"
						>
							{readFlag ? "View Less" : "... View More"}
						</button>
					)}
				</div>
			</div>

			<div className="flex items-center sm:gap-x-3 gap-x-1.5 mb-4">
				<div className="flex items-center bg-[#F0EDFF] py-1.5 px-2 rounded">
					<p className="text-[#8877D8] font-medium sm:text-xs text-[10px] heading-font">
						{data.business_tag?.tagname}
					</p>
				</div>
				<img src={line} className="h-3 mt-0.5" alt="separator" />
				<div className="flex items-center gap-x-1 text-[#667085]">
					<FaStar className="w-3.5 h-3.5" />
					<div className="sm:text-sm text-xs text-[#667085] flex font-normal align-middle">
						<p>{data.average_review_stars}</p>
						<p className="ms-1">({data.review?.length})</p>
					</div>
				</div>
				<img src={line} className="h-3 mt-0.5" alt="separator" />
				{displayServiceType === "both" ? (
					<>
						<div className="flex items-center bg-[#DEFFFD] py-1.5 px-2 rounded">
							<div className="mr-2">
								<img
									src={homeservice}
									alt="home service icon"
									className="w-4 h-4"
								/>
							</div>
							<p className="text-[#8877D8] font-medium sm:text-xs text-[10px]">
								Home Service
							</p>
						</div>
						<div className="flex items-center bg-[#FFE8C8] py-1.5 px-2 rounded">
							<div className="mr-2">
								<img src={walkin} alt="walk-in icon" className="w-2 h-4" />
							</div>
							<p className="text-[#FF5B00] font-medium sm:text-xs text-[10px]">
								Walk-in
							</p>
						</div>
					</>
				) : displayServiceType === "home_service" ? (
					<>
						<div className="flex items-center bg-[#DEFFFD] py-1.5 px-2 rounded">
							<div className="mr-2">
								<img
									src={homeservice}
									alt="home service icon"
									className="w-4 h-4"
								/>
							</div>
							<p className="text-[#8877D8] font-medium sm:text-xs text-[10px]">
								Home Service
							</p>
						</div>
					</>
				) : displayServiceType === "walk_in" ? (
					<>
						<div className="flex items-center bg-[#FFE8C8] py-1.5 px-2 rounded">
							<div className="mr-2">
								<img src={walkin} alt="walk-in icon" className="w-2 h-4" />
							</div>
							<p className="text-[#FF5B00] font-medium sm:text-xs text-[10px]">
								Walk-in
							</p>
						</div>
					</>
				) : null}
			</div>

			<div className="flex items-center gap-x-1 text-gray-600">
				<img src={distance1} className="w-4 h-4" alt="location" />
				<p className="sm:text-sm text-xs text-[#475467] font-normal align-middle">
					{data.business_address}
				</p>
			</div>

			<div className="mt-7">
				<div className="col-span-12 flex items-center bg-[#EAECF0] p-1 rounded-lg">
					{tabButtons.map((button, index) => (
						<React.Fragment key={button.id}>
							{index > 0 && currentTab !== index - 1 && (
								<div className="h-4 text-[#8E8E93] my-auto border border-[#8E8E9340]"></div>
							)}
							<button
								role="tab"
								type="button"
								onClick={() => {
									localStorage.setItem("tabFlag", button.id.toString());
									onTabChange(button.id);
								}}
								className={`col-span-4 w-full sm:text-[0.813rem] text-xs py-2.5 px-2.5 h-full rounded-lg outline-none rounded-full transition-all duration-300 ${
									currentTab === button.id
										? "bg-white shadow-md font-medium"
										: "bg-transparent"
								}`}
							>
								{button.label}
							</button>
						</React.Fragment>
					))}
				</div>
			</div>
		</div>
	);
};

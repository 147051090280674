import { useCallback, useEffect, useState } from "react";
import { getMoreToDiscover } from "../Services/ApiService";
import toast from "react-hot-toast";
import { DiscoverData } from "../types/service";
import { useNavigate } from "react-router-dom";

export const useMoreDiscover = (id: string, cid: string) => {
	const navigate = useNavigate();
	const [moreDiscover, setMoreDiscover] = useState<DiscoverData[]>([]);

	useEffect(() => {
		const fetchMoreDiscover = async () => {
			try {
				const params = { category_id: cid, service_id: id };
				const response = await getMoreToDiscover(params);

				if (response.data.status === 1) {
					setMoreDiscover(response.data.data.slice(0, 3));
				}
			} catch (err: any) {
				toast.error(err.response?.data?.Message || "An error occurred.");
			}
		};

		fetchMoreDiscover();
	}, [id, cid]);

	const handleNavigate = useCallback(
		(data: DiscoverData) => {
			navigate(
				`/service/${data?.business_service[0]?.id}/${data?.business_service[0]?.category_id}`
			);
		},
		[navigate]
	);

	return { moreDiscover, handleNavigate };
};

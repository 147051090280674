import { useCallback, useEffect, useState } from "react";
import { convertToMinutes } from "../utils/formatters";
import { Service } from "../types/service";

export const useAddonServices = (addonServices: Service[]) => {
	const [selectedAddons, setSelectedAddons] = useState<Set<number>>(new Set());
	const [totalPrice, setTotalPrice] = useState(0);
	const [totalTime, setTotalTime] = useState(0);
	const [totalAddonPrice, setTotalAddonPrice] = useState(0);
	const [totalAddonTime, setTotalAddonTime] = useState(0);

	const handleAddonSelection = useCallback((addon: Service) => {
		setSelectedAddons((prev) => {
			const next = new Set(prev);
			if (next.has(addon.id)) {
				next.delete(addon.id);
			} else {
				next.add(addon.id);
			}
			return next;
		});
	}, []);

	useEffect(() => {
		const newTotalAddonPrice = Array.from(selectedAddons).reduce((sum, id) => {
			const addon = addonServices.find((a) => a.id === id);
			return sum + (addon?.price || 0);
		}, 0);

		const newTotalAddonTime = Array.from(selectedAddons).reduce((sum, id) => {
			const addon = addonServices.find((a) => a.id === id);
			return sum + (addon?.duration ? convertToMinutes(addon.duration) : 0);
		}, 0);

		setTotalAddonPrice(newTotalAddonPrice);
		setTotalAddonTime(newTotalAddonTime);
		setTotalPrice(newTotalAddonPrice);
		setTotalTime(newTotalAddonTime);
	}, [selectedAddons, addonServices]);

	return {
		totalPrice,
		totalTime,
		selectedServiceCount: selectedAddons.size,
		totalAddonPrice,
		totalAddonTime,
		handleAddonSelection,
	};
};

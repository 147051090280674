import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { handleFinalStep } from "../../Services/ApiService";
import toast from "react-hot-toast";

interface FormValues {
	full_name: string;
	email: string;
}

export default function PersonalInfo() {
	const navigate = useNavigate();

	const validationSchema = Yup.object({
		full_name: Yup.string()
			.matches(/^[A-Za-z\s]+$/, "Full name must only contain letters")
			.required("Full name is required"),
		email: Yup.string()
			.email("Invalid email format")
			.required("Email is required"),
	});

	const handleDonePage = (full_name: string, email: string) => {
		const params = { email_id: email, fullname: full_name };
		handleFinalStep(params)
			.then((res) => {
				const status = res.data.status;
				const data = res.data;
				if (status === 1) {
					toast.success(res.data.message);
					navigate("/review");
					localStorage.setItem(
						"userData",
						JSON.stringify(data.updatedProfileData)
					);
				} else {
					toast.error(res.data.message);
				}
			})
			.catch((err) => {
				if (err.response) {
					const errs = err.response.data;
					const status = err.response?.status;

					if (status === 400) {
						toast.error(errs?.message);
					} else {
						toast.error(errs?.Message || "An error occurred.");
					}
				}
			});
	};

	const google_user = JSON.parse(localStorage.getItem("google_user") as string);
	console.log("google user", google_user);

	return (
		<div className="container mx-auto my-10">
			<div className="pb-5">
				<div className="mb-8">
					<p className="font-medium sm:text-3xl text-2xl pb-2 heading-font">
						Final steps...
					</p>
					<p className="text-gray-500 text-xs sm:text-sm">
						Almost done! Please share these final details to get started.
					</p>
				</div>

				<Formik
					initialValues={{
						full_name: "",
						email: "",
					}}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						handleDonePage(
							google_user?.displayName || values.full_name,
							google_user?.email || values.email
						);
					}}
				>
					{({ isValid, dirty }) => (
						<Form>
							<div className="mb-10">
								<p className="sm:text-sm text-xs font-medium text-gray-800 pb-1.5 heading-font">
									Full Name
								</p>
								<Field
									type="text"
									name="full_name"
									placeholder="Enter your full name"
									className="py-3.5 px-5 font-normal text-gray-800 border-2 border-gray-300 rounded-[14px] w-full outline-none sm:text-base text-sm"
								/>
								<ErrorMessage
									name="full_name"
									component="p"
									className="text-red-500 text-xs mt-1"
								/>
							</div>

							<div>
								<p className="text-xs sm:text-sm font-medium text-gray-800 pb-1.5 heading-font">
									Email Address
								</p>
								<Field
									type="email"
									name="email"
									placeholder="Enter your email address"
									className="py-3.5 px-5 font-normal text-gray-800 border-2 border-gray-300 rounded-[14px] w-full outline-none sm:text-base text-sm"
								/>
								<ErrorMessage
									name="email"
									component="p"
									className="text-red-500 text-xs mt-1"
								/>
							</div>

							<button
								type="submit"
								className={`rounded-2xl py-4 w-full font-medium sm:text-lg mt-10 ${
									isValid && dirty
										? "bg-[#8877D8] text-white"
										: "bg-gray-300 text-gray-400 cursor-not-allowed"
								}`}
								disabled={!isValid || !dirty}
							>
								Continue
							</button>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}

import React from "react";
import nodata from "../../assets/images/no-data-concept1.png";
const NoData = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <img src={nodata} alt="" />
      </div>
    </div>
  );
};

export default NoData;

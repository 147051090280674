import React from "react";
import { IoClose } from "react-icons/io5";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { components } from "react-select";
import distance from "../../assets/images/distance.png";
import distance1 from "../../assets/images/distance_gray.png";
import { AddressOption } from "../../types/business";
import Dialog from "../Dialog/Dialog";

interface AddressDialogProps {
	isOpen: boolean;
	onClose: () => void;
	address: AddressOption | undefined;
	currentAddress: string | undefined;
	onAddressChange: (value: AddressOption | null) => void;
}

const CustomOption = (props: any) => (
	<components.Option {...props}>
		<div className="flex items-center">
			<img
				src={distance1}
				alt="location icon"
				style={{ width: "1rem", marginRight: "10px" }}
			/>
			{props.data.label}
		</div>
	</components.Option>
);

export const AddressDialog: React.FC<AddressDialogProps> = ({
	isOpen,
	onClose,
	address,
	currentAddress,
	onAddressChange,
}) => (
	<Dialog open={isOpen} onClose={onClose} className="relative z-50" size="lg">
		<div className="fixed inset-0 flex w-screen items-center justify-center p-4 mx-5">
			<Dialog.Panel className="max-w-lg border bg-white rounded-xl shadow-lg">
				<Dialog.Description>
					<div className="flex justify-end items-center">
						<div className="rounded-full w-7 h-7 bg-gray-100 flex items-center justify-between">
							<IoClose
								className="cursor-pointer w-7 size-6 text-[#667085]"
								onClick={onClose}
							/>
						</div>
					</div>

					<div className="pb-5 sm:px-5 px-2">
						<p className="text-center font-medium sm:text-xl text-lg pb-4 heading-font">
							Address
						</p>
						<div className="w-full relative flex items-center py-2 border border-gray-300 rounded-[14px] my-2 pe-4">
							<div className="w-full flex items-center">
								<img src={distance} alt="location icon" className="ms-3 w-6" />
								<GooglePlacesAutocomplete
									apiKey="AIzaSyDuqKaM8WQIZNWbdu2VAVaYrr2I7ZjSpOM"
									selectProps={{
										value: address,
										onChange: onAddressChange,
										placeholder: "Enter a new address",
										components: {
											Option: CustomOption,
										},
										styles: {
											control: (provided) => ({
												...provided,
												width: "100%",
												border: "none",
												boxShadow: "none",
											}),
											input: (provided) => ({
												...provided,
												width: "100%",
												border: "none",
												boxShadow: "none",
											}),
											menu: (provided) => ({
												...provided,
												width: "calc(100% + 2.7rem)",
												marginLeft: "-2rem",
												boxShadow: "none",
												border: "1px solid rgb(209 213 219)",
											}),
											indicatorsContainer: (provided) => ({
												...provided,
												display: "none",
											}),
											indicatorSeparator: (provided) => ({
												...provided,
												display: "none",
											}),
										},
									}}
								/>
							</div>
						</div>

						<div className="py-5 border-b">
							<p className="font-medium sm:text-sm text-xs pb-1">
								Use your current location
							</p>
							<p
								className="text-gray-600 font-normal sm:text-sm text-xs cursor-pointer"
								onClick={() => {
									onAddressChange({
										label: currentAddress as string,
										value: { place_id: "" },
									});
									onClose();
								}}
							>
								{currentAddress}
							</p>
						</div>
					</div>
				</Dialog.Description>
			</Dialog.Panel>
		</div>
	</Dialog>
);

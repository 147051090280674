import React from "react";
import { DiscoverData } from "../../types/service";
import { FiChevronRight } from "react-icons/fi";
import Slider from "react-slick";
import { DiscoverCard } from "./DiscoverCard";

interface MoreToDiscoverProps {
	data: DiscoverData[];
	onNavigate: (data: DiscoverData) => void;
	navigate: (path: string) => void;
}

export const MoreToDiscover: React.FC<MoreToDiscoverProps> = ({
	data,
	onNavigate,
	navigate,
}) => {
	const settings = {
		arrows: false,
		speed: 500,
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		centerMode: true,
	};

	if (!data.length) return null;

	return (
		<div className="border-t">
			<div
				className="flex items-center gap-x-1.5 mt-7 cursor-pointer"
				onClick={() => navigate("/more_to_discover")}
			>
				<p className="heading-font font-medium sm:text-lg text-base text-[#1D2939]">
					More for you to discover
				</p>
				<div className="flex items-center justify-center cursor-pointer rounded-full w-5 h-5 bg-gray-100">
					<FiChevronRight className="w-4" />
				</div>
			</div>

			<div className="mt-4">
				{data.length > 1 ? (
					<Slider {...settings}>
						{data.map((item, index) => (
							<DiscoverCard
								key={index}
								item={item}
								index={index}
								onNavigate={onNavigate}
								navigate={navigate}
							/>
						))}
					</Slider>
				) : (
					data.map((item, index) => (
						<DiscoverCard
							key={index}
							item={item}
							index={index}
							onNavigate={onNavigate}
							navigate={navigate}
						/>
					))
				)}
			</div>
		</div>
	);
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import p6 from "../../assets/images/UsersThree.png";
import { FiChevronLeft } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getButler } from "../../Services/ApiService";
import toast from "react-hot-toast";
import logo from "../../assets/images/logo.png";
import { isIOS } from "react-device-detect";
import { useServiceDetails } from "../../hooks/useServiceDetails";

interface Business {
	id: number;
}

interface Butler {
	id: number;
}

export default function ButlerDetails() {
	const navigate = useNavigate();

	const category_id = localStorage.getItem("categoryId");
	const service_id = localStorage.getItem("serviceId");

	const businessData: Business | null = localStorage.getItem("businessData")
		? JSON.parse(localStorage.getItem("businessData")!)
		: null;

	const butler: Butler | null = localStorage.getItem("butler")
		? JSON.parse(localStorage.getItem("butler")!)
		: null;

	const butlerId: { id: number } | null = localStorage.getItem("butlerId")
		? JSON.parse(localStorage.getItem("butlerId")!)
		: null;

	const [loader, setLoader] = useState<boolean>(false);
	const [selectedDiv, setSelectedDiv] = useState<any>(null);
	const [butlers, setButlers] = useState<any>([]);

	useEffect(() => {
		if (businessData && service_id) {
			if (butler) {
				setButlers(butler);
				setSelectedDiv(butlerId?.id);
			} else {
				getButlerData();
			}
		} else {
			navigate("/");
		}
	}, []);

	const handleDivClick = (item: any) => {
		setSelectedDiv(item.id);
		localStorage.setItem("butlerId", JSON.stringify(item));
	};

	// const handleButlerBack = () => {
	//   localStorage.removeItem("butlerId");
	//   localStorage.removeItem("butler");
	//   navigate(`/service/${service_id}/${category_id}`);
	// };

	const { handleServiceBack } = useServiceDetails(service_id as string);
	const getButlerData = () => {
		setLoader(true);

		const params: {
			service_id: number;
			user_id: any;
		} = {
			service_id: Number(service_id),
			user_id: businessData?.id,
		};

		getButler(params)
			.then((res) => {
				setLoader(false);
				const status = res.data.status;
				if (status === 1) {
					setButlers(res.data.data);
				} else {
					toast.error(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				const errs = err.response?.data;
				const status = err.response?.status;
				if (status === 422) {
					toast.error(errs?.errors[0]?.msg);
				} else {
					toast.error(errs?.Message || "An error occurred.");
				}
			});
	};

	const handleButlerData = () => {
		localStorage.setItem("butler", JSON.stringify(butlers));
		navigate("/book_appointment", {
			state: { businessId: businessData?.id, serviceId: service_id },
		});
	};

	return (
		<div>
			<div className="sticky top-0 z-10 bg-white ">
				<div className="border-b pb-4 sm:w-[30rem] sm:mx-auto">
					<div className="container mx-auto ">
						<div className="px-4 py-3 bg-[#E7E4F7] rounded-2xl flex justify-between items-center">
							<div className="flex items-center gap-x-2 sm:w-1/2">
								<img src={logo} alt="Beauty Butler" className="w-12 h-12" />
								<div>
									<p className="sm:text-sm text-xs font-normal heading-font">
										Beauty Butler
									</p>
									<p className="sm:text-xs text-[10px] text-[#667085] font-normal">
										Beauty treatments direct to your doorstep
									</p>
								</div>
							</div>
							<a
								className="bg-[#8877D8] text-white rounded-lg py-2 sm:px-4 sm:w-auto w-44 font-medium text-xs text-center ms-2"
								href={`${
									isIOS
										? "https://apps.apple.com/app/id6608976314"
										: "https://play.google.com/store/apps/details?id=com.beautybutler.use"
								}`}
								target="_blank"
							>
								Download App
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-4 container mx-auto ">
				<div className="rounded-2xl flex  items-center gap-x-24 mb-6">
					<div
						className="flex items-center justify-center cursor-pointer rounded-full w-7 h-7 bg-gray-100"
						onClick={handleServiceBack}
					>
						<FiChevronLeft className="w-4" />
					</div>
					<p className="sm:text-xl text-lg text-[#1D2939] font-medium heading-font">
						Select a Butler
					</p>
				</div>
				{/* {butlers.length ? (
          <> */}
				<div className="grid grid-cols-12 gap-4 mb-32">
					<div
						key={0}
						className={`border col-span-6 rounded-3xl sm:py-11 py-4 flex flex-col items-center justify-center cursor-pointer ${
							selectedDiv === 0
								? "border-[#8877D8] ring-4 ring-[#8877D820]"
								: ""
						}`}
						aria-selected={selectedDiv === 0 ? "true" : "false"}
						onClick={() => handleDivClick({ id: 0, fullname: "Any Butler" })}
					>
						<div className="h-[70px] flex items-center justify-center">
							{loader ? (
								<Skeleton circle={true} className="h-10 w-10" />
							) : (
								<img src={p6} className="w-10 h-10" alt="any Butler" />
							)}
						</div>
						<p className="pt-4 sm:text-sm text-xs">Any Butler</p>
					</div>
					{loader ? (
						<SkeletonTheme
							highlightColor="#ffffff30"
							borderRadius="0.5rem"
							duration={5}
						>
							<div className="col-span-6">
								<Skeleton height={200} />
							</div>
						</SkeletonTheme>
					) : (
						butlers.length != 0 &&
						butlers.map((item: any, index: number) => (
							<div
								key={index}
								className={`border col-span-6 rounded-3xl sm:py-11 py-4 flex flex-col items-center justify-center cursor-pointer ${
									selectedDiv === item.id
										? "border-[#8877D8] ring-4 ring-[#8877D820]"
										: ""
								}`}
								aria-selected={selectedDiv === item.id ? "true" : "false"}
								onClick={() => handleDivClick(item)}
							>
								{loader ? (
									<Skeleton circle={true} height={70} width={70} />
								) : (
									<img
										src={item.profile_image}
										className="w-[70px] h-[70px] rounded-full"
										alt={item.fullname}
									/>
								)}

								<p className="pt-4 sm:text-sm text-xs">{item.fullname}</p>
								<p className="pt-1 flex items-center gap-x-1">
									<FaStar className="w-3.5 h-3.5" />
									<span className="sm:text-sm text-xs font-normal text-[#667085]">
										{item.average_rating.toFixed(2)}
									</span>
								</p>
							</div>
						))
					)}
				</div>
				<div className="fixed inset-x-0 bottom-0 z-10 bg-white border-t sm:w-[30rem] sm:mx-auto">
					<div className="mt-2 py-4 container mx-auto">
						<button
							className={`bg-[#8877D8] rounded-2xl py-4 w-full font-medium sm:text-lg ${
								selectedDiv !== null
									? "bg-[#8877D8] text-white"
									: "bg-gray-300 text-gray-400 cursor-not-allowed"
							}`}
							onClick={() => handleButlerData()}
							disabled={selectedDiv === null}
						>
							Continue
						</button>
					</div>
				</div>
				{/* </>
        ) : (
          <div className="flex items-center justify-center h-[80vh] bg-gray-100">
            <div className="text-center">
              <img src={nodata} alt="" />
            </div>
          </div>
        )} */}
			</div>
		</div>
	);
}

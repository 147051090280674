import React, { useEffect, useMemo, useState, useCallback } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import "react-international-phone/style.css";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { sendOtp, verifyOtp } from "../../Services/ApiService";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { ThreeDots } from "react-loader-spinner";

interface PhoneNumberData {
	country_code: string;
	iso_code: string;
	phone_number: string;
}

interface OtpVerificationParams {
	countryCode: string;
	dialCode: string;
	inputValue: string;
	otp: string;
	device_id: string;
	device_token: string;
	device_type: string;
}

const RESEND_TIMEOUT = 60;
const STORAGE_KEYS = {
	PHONE_DATA: "phone_data",
	TOKEN: "token",
	USER_DATA: "userData",
	BUTLER_FLAG: "butlerFlag",
} as const;

export default function Verification() {
	const location = useLocation();
	const navigate = useNavigate();

	const [otp, setOtp] = useState<string>("");
	const [remainingTime, setRemainingTime] = useState<number>(RESEND_TIMEOUT);
	const [deviceId, setDeviceId] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [attempts, setAttempts] = useState<number>(0);

	// Enhanced error handling for phone data
	const phoneData: {
		countryCode: string;
		dialCode: string;
		inputValue: string;
	} = useMemo(() => {
		try {
			const storedData = localStorage.getItem(STORAGE_KEYS.PHONE_DATA);
			if (!storedData) {
				console.error("No phone data found in localStorage");
				return null;
			}
			const parsedData = JSON.parse(storedData);
			console.log("Retrieved phone data:", parsedData);
			return parsedData;
		} catch (error) {
			console.error("Error parsing phone data:", error);
			toast.error("Error retrieving phone data");
			return null;
		}
	}, []);

	// Redirect if no phone data
	useEffect(() => {
		if (!phoneData) {
			console.warn("No phone data available, redirecting to login");
			navigate("/login");
		}
	}, [phoneData, navigate]);

	// Enhanced timer with proper cleanup
	useEffect(() => {
		console.log("Timer started with remaining time:", remainingTime);
		if (remainingTime <= 0) return;

		const interval = setInterval(() => {
			setRemainingTime((prevTime) => {
				const newTime = prevTime - 1;
				console.log("Updated remaining time:", newTime);
				return newTime;
			});
		}, 1000);

		return () => {
			console.log("Cleaning up timer");
			clearInterval(interval);
		};
	}, [remainingTime]);

	// Generate device ID only once
	useEffect(() => {
		const generatedDeviceId = uuidv4();
		console.log("Generated device ID:", generatedDeviceId);
		setDeviceId(generatedDeviceId);
	}, []);

	// Enhanced verification handler
	const handleVerification = useCallback(async () => {
		if (!phoneData) {
			console.error("Phone data missing during verification");
			toast.error("Phone data is missing");
			return;
		}

		if (attempts >= 3) {
			console.warn("Maximum verification attempts reached");
			toast.error("Too many attempts. Please try again later.");
			return;
		}

		const params = {
			country_code: phoneData.dialCode,
			iso_code: phoneData.countryCode,
			phone_number: phoneData.inputValue,
			otp: otp,
			device_id: deviceId,
			device_token: "abcd",
			device_type: "Web",
		};

		console.log("Attempting verification with params:", params);
		setIsLoading(true);

		try {
			const res = await verifyOtp(params);

			if (res.data.status === 1) {
				localStorage.setItem(STORAGE_KEYS.TOKEN, res.data.token);
				localStorage.setItem(
					STORAGE_KEYS.USER_DATA,
					JSON.stringify(res.data.user)
				);

				if (res.data.user?.fullname) {
					localStorage.removeItem(STORAGE_KEYS.BUTLER_FLAG);
					navigate("/review");
				} else {
					navigate("/personal_info");
				}
				toast.success(res.data.message);
			} else {
				setAttempts((prev) => prev + 1);
				toast.error(res.data.message);
			}
		} catch (err: any) {
			console.error("Verification error:", err);
			setAttempts((prev) => prev + 1);

			const errorMessage =
				err.response?.data?.message || "An error occurred during verification";
			toast.error(errorMessage);
		} finally {
			setIsLoading(false);
		}
	}, [phoneData, otp, deviceId, attempts, navigate]);

	// Enhanced OTP resend handler
	const handleSendOtp = useCallback(async () => {
		if (!phoneData) {
			console.error("Phone data missing during OTP resend");
			toast.error("Phone data is missing");
			return;
		}

		const params = {
			country_code: phoneData.dialCode,
			iso_code: phoneData.countryCode,
			phone_number: phoneData.inputValue,
		};

		try {
			const res = await sendOtp(params);
			console.log("OTP send response:", res);

			if (res.data.status === 1) {
				setRemainingTime(RESEND_TIMEOUT);
				toast.success(res.data.message);
			} else {
				toast.error(res.data.message);
			}
		} catch (err: any) {
			console.error("OTP send error:", err);
			const errorMessage =
				err.response?.data?.message || "An error occurred while sending OTP";
			toast.error(errorMessage);
		}
	}, [phoneData]);

	// Handle OTP input
	const handleOtpChange = (value: string) => {
		console.log("OTP input changed:", value);
		setOtp(value);
	};

	return (
		<div className="container mx-auto my-10">
			<div className="rounded-full w-7 h-7 bg-gray-100 flex items-center justify-center mb-6">
				<FaChevronLeft
					className="cursor-pointer w-4 text-gray-500"
					onClick={() => navigate(-1)}
				/>
			</div>
			<div className="pb-5">
				<div className="mb-8">
					<p className="font-medium sm:text-3xl text-2xl pb-2 heading-font">
						Verify your phone number
					</p>
					<p className="text-gray-500 sm:text-sm text-xs">
						Please enter the verification code sent to {phoneData?.countryCode}
						{phoneData?.inputValue}
					</p>
				</div>
				<div className="flex justify-center mb-4">
					<OTPInput
						value={otp}
						onChange={handleOtpChange}
						numInputs={4}
						inputType="tel"
						renderInput={(props) => (
							<input
								{...props}
								style={{
									backgroundColor: props.value
										? "rgb(255, 255, 255)"
										: "rgb(255, 255, 255)",
									border: props.value
										? "1.5px solid rgb(136, 119, 216)"
										: "1.5px solid rgba(208, 213, 221, 1)",
									outline: props.value
										? "solid 4px rgba(136, 119, 216, 0.125) "
										: "none",
								}}
								className="handleclosj custom-outline"
								required
							/>
						)}
					/>
				</div>
				<div className="mt-0.5 text-center font-medium sm:text-sm text-xs">
					{remainingTime > 0 ? (
						<p className="text-gray-500">
							Resend code in 00:{remainingTime.toString().padStart(2, "0")}
						</p>
					) : (
						<p
							className="text-[#8877D8] cursor-pointer"
							onClick={handleSendOtp}
						>
							Resend code?
						</p>
					)}
				</div>
				<button
					className={`bg-[#8877D8] rounded-2xl py-4 w-full font-medium sm:text-lg mt-10 flex items-center justify-center ${
						otp.length === 4
							? "bg-[#8877D8] text-white"
							: "bg-gray-300 text-gray-400 cursor-not-allowed"
					}`}
					onClick={handleVerification}
					disabled={otp.length < 4 || isLoading || attempts >= 3}
				>
					{isLoading ? (
						<ThreeDots height="28" width="50" color="#fff" visible={true} />
					) : (
						"Verify"
					)}
				</button>
				<p
					className="pt-4 text-gray-500 font-medium sm:text-sm text-xs text-center"
					onClick={() => navigate("/login")}
				>
					Not your phone number?{" "}
					<span className="text-[#8877D8] cursor-pointer">
						Change phone number
					</span>
				</p>
			</div>
		</div>
	);
}

import React from "react";
import { DiscoverData } from "../../types/service";
import line from "../../assets/images/Line.png";
import like from "../../assets/images/like.png";
import walk from "../../assets/images/walk-in.png";
import { FaStar } from "react-icons/fa";

interface DiscoverCardProps {
	item: DiscoverData;
	index: number;
	onNavigate: (data: DiscoverData) => void;
	navigate: (path: string) => void;
}

export const DiscoverCard: React.FC<DiscoverCardProps> = ({
	item,
	index,
	onNavigate,
	navigate,
}) => {
	return (
		<div className={`${index !== 0 ? "pl-4" : ""}`}>
			<img
				src={item?.business_service[0]?.service_images[0]?.service_image}
				className="object-cover w-full sm:h-[25rem] h-[350px] rounded-3xl cursor-pointer"
				onClick={() => onNavigate(item)}
				alt={item?.business_service[0]?.service_name}
			/>
			<div className="flex items-center justify-end -mt-10 me-2 relative cursor-pointer">
				<img
					src={like}
					alt=""
					className="h-8 w-8"
					onClick={(e) => {
						e.stopPropagation();
						navigate("/login");
					}}
				/>
			</div>
			<div className="mt-5">
				<p className="text-base sm:text-lg font-medium text-[#1D2939]">
					{item.business_name}
				</p>
				<p className="pt-2 sm:text-base text-sm text-[#667085]">
					{item?.business_service[0]?.service_name}
				</p>
				<div className="flex items-center sm:gap-x-2 gap-x-1">
					<div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
						<FaStar className="w-3.5 h-3.5" />
						<p className="sm:text-base text-sm text-[#667085]">
							{item?.business_service[0]?.average_rating.toFixed(2)} (
							{item?.review?.length})
						</p>
						<img src={line} className="h-4" alt="" />
					</div>
					<div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
						<img src={walk} alt="" className="h-3.5" />
						<p className="sm:text-base text-sm text-[#667085]">
							{item?.duration || "0 min"}
						</p>
						<img src={line} className="h-4" alt="" />
					</div>
					<div className="pt-3 flex items-center sm:gap-x-2 gap-x-1">
						<p className="sm:text-base text-sm text-[#667085]">
							from ₦
							{Number(
								item?.business_service[0]?.walk_in_price
							).toLocaleString()}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "../Components/ErrorPage/ErrorPage";
import NoData from "../Components/Nodata/NoData";
import Home from "../pages/Home/Home";
import ServiceDetails from "../pages/ServiceDetails/ServiceDetails";
import MoreToDiscover from "../pages/MoreToDiscover/MoreToDiscover";
import ButlerDetails from "../pages/ButlerDetails/ButlerDetails";
import BookAppointment from "../pages/BookAppointment/BookAppointment";
import Login from "../pages/Login/Login";
import Verification from "../pages/Verification/Verification";
import PersonalInfo from "../pages/PersonalInfo/PersonalInfo";
import Review from "../pages/Review/Review";
import CheckOutPage from "../pages/CheckOutPage/CheckOutPage";
import SessionBooked from "../pages/SessionBooked/SessionBooked";
import BookingDetails from "../pages/BookingDetails/BookingDetails";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<ErrorPage />} />
        <Route path="/404_error" element={<ErrorPage />} />
        <Route path="/no_data_found" element={<NoData />} />
        <Route path="/" element={<Home />} />
        <Route path="/service/:id/:cid" element={<ServiceDetails />} />
        <Route path="/more_to_discover" element={<MoreToDiscover />} />
        <Route path="/butler" element={<ButlerDetails />} />
        <Route path="/book_appointment" element={<BookAppointment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/personal_info" element={<PersonalInfo />} />
        <Route path="/review" element={<Review />} />
        <Route path="/checkout" element={<CheckOutPage />} />
        <Route path="/session_booked" element={<SessionBooked />} />
        <Route path="/booking_details" element={<BookingDetails />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

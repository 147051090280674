import React from "react";
import { IoClose } from "react-icons/io5";
import Dialog from "../Dialog/Dialog";

interface ImageDialogProps {
	isOpen: boolean;
	onClose: () => void;
	image?: string;
}

export const ImageDialog: React.FC<ImageDialogProps> = ({
	isOpen,
	onClose,
	image,
}) => (
	<Dialog open={isOpen} onClose={onClose} className="relative z-50" size="lg">
		<div className="fixed inset-0 flex w-screen items-center justify-center p-4 container mx-5">
			<Dialog.Panel className="max-w-lg border bg-white rounded-xl shadow-lg">
				<Dialog.Description>
					<div className="flex justify-end items-center">
						<div className="rounded-full w-7 h-7 bg-gray-100 flex items-center justify-between">
							<IoClose
								className="cursor-pointer w-7 size-6 text-[#667085]"
								onClick={onClose}
							/>
						</div>
					</div>

					<div className="md:h-96 h-80">
						<div className="w-full md:h-96 h-80 flex items-center pt-2 md:py-5">
							<img
								src={image}
								alt="location icon"
								className="object-cover h-full w-full"
							/>
						</div>
					</div>
				</Dialog.Description>
			</Dialog.Panel>
		</div>
	</Dialog>
);

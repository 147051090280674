import React from "react";
import { BusinessService } from "../../types/service";
import walkin from "../../assets/images/walk-in.png";
import homeservice from "../../assets/images/homeservice.png";

interface ServiceTypeSelectionProps {
	serviceType: {
		home_service: boolean;
		walk_in: boolean;
	};
	onChange: (type: "home_service" | "walk_in") => void;
	service: BusinessService | null;
}

export const ServiceTypeSelection: React.FC<ServiceTypeSelectionProps> = ({
	serviceType,
	onChange,
	service,
}) => {
	if (!service) return null;

	return (
		<div className="container mx-auto">
			<div className="flex justify-between items-center mb-4">
				<h2 className="text-lg flex items-center space-x-2">
					<span className="heading-font">Select Your Service Type</span>
					<span className="px-2 py- border border-[#FF5B00] bg-white text-[#FF5B00] rounded-md text-xs">
						Required
					</span>
				</h2>
			</div>

			{service.walk_in_price !== null && (
				<div
					className="flex justify-between items-center mb-4 clicked-item cursor-pointer"
					onClick={() => onChange("walk_in")}
				>
					<div className="flex items-center space-x-2">
						<div className="bg-[#FFE8C8] px-2 py-1 rounded inline-flex items-center justify-center">
							<img src={walkin} alt="Walk in Icon" className="w-4 h-4" />
						</div>
						<span className="text-sm font-semibold text-[#1D2939]">
							Walk In
						</span>
					</div>
					<div className="flex items-center space-x-2">
						<span>₦ {Number(service.walk_in_price).toLocaleString()}</span>
						<input
							checked={serviceType.walk_in}
							onChange={(e) => e.stopPropagation()} // Prevents bubbling
							type="radio"
							name="radio"
							className="form-radio"
						/>
					</div>
				</div>
			)}

			{service.home_service_price !== null && (
				<div
					className="flex justify-between items-center mb-4 clicked-item cursor-pointer"
					onClick={() => onChange("home_service")}
				>
					<div className="flex items-center space-x-2">
						<div className="bg-[#DEFFFD] px-2 py-1 rounded inline-flex items-center justify-center">
							<img
								src={homeservice}
								alt="Home Service Icon"
								className="w-4 h-4"
							/>
						</div>
						<span className="text-sm text-[#1D2939]">Home Service</span>
					</div>
					<div className="flex items-center space-x-2">
						<span>₦ {Number(service.home_service_price).toLocaleString()}</span>
						<input
							checked={serviceType.home_service}
							onChange={(e) => e.stopPropagation()} // Prevents bubbling
							type="radio"
							name="radio"
							className="form-radio"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

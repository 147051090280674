import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DateContextType {
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  formattedDate: string;
  setFormattedDate: React.Dispatch<React.SetStateAction<string>>;
}

const DateContext = createContext<DateContextType | undefined>(undefined);

export const useDate = (): DateContextType => {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error("useDate must be used within a DateProvider");
  }
  return context;
};

const formatDateWithOrdinal = (date: Date): string => {
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );
  const year = date.getFullYear();

  const getOrdinalSuffix = (day: number): string => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
};

interface DateProviderProps {
  children: ReactNode;
}

export const DateProvider: React.FC<DateProviderProps> = ({ children }) => {
  const today = new Date();
  const formattedToday = formatDateWithOrdinal(today);

  const [date, setDate] = useState<string>(today.toISOString().split("T")[0]);
  const [formattedDate, setFormattedDate] = useState<string>(formattedToday);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    const currentFormattedDate = formatDateWithOrdinal(new Date());
    setDate(currentDate);
    setFormattedDate(currentFormattedDate);
  }, []);

  return (
    <DateContext.Provider
      value={{ date, setDate, formattedDate, setFormattedDate }}
    >
      {children}
    </DateContext.Provider>
  );
};

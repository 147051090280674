import toast from "react-hot-toast";
import { getBussiness } from "../Services/ApiService";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BusinessService } from "../types/service";

export const useServiceDetails = (id: string) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [singleService, setSingleService] = useState<BusinessService | null>(
		null
	);
	const [selectedService, setSelectedService] = useState<Record<string, any>>(
		{}
	);

	useEffect(() => {
		const fetchServiceData = async () => {
			try {
				setLoading(true);
				const businessData = JSON.parse(
					localStorage.getItem("businessData") || "{}"
				);
				const params = {
					business_id: businessData.id,
					service_id: id,
				};

				const response = await getBussiness(params);
				if (response.data.status === 1) {
					setSelectedService(response.data.data);
					setSingleService(response.data.data?.business_service[0]);
				}
			} catch (err: any) {
				toast.error(err.response?.data?.Message || "An error occurred.");
			} finally {
				setLoading(false);
			}
		};

		fetchServiceData();
	}, [id]);

	const handleServiceBack = useCallback(() => {
		[
			"mainService",
			"selectedService",
			"moreDiscover",
			"selectedAddon",
			"timeSlot",
			"selectedTime",
			"date",
		].forEach((key) => localStorage.removeItem(key));
		if (window.history.length > 1) {
			window.history.back();
		}
		return;
	}, []);

	return {
		loading,
		singleService,
		selectedService,
		handleServiceBack,
	};
};

import React from "react";
import { FiChevronDown } from "react-icons/fi";
import distance from "../../assets/images/distance.png";
import { AddressOption } from "../../types/business";

interface AddressBarProps {
	address: AddressOption | undefined;
	currentAddress: string | undefined;
	onAddressClick: () => void;
}

export const AddressBar: React.FC<AddressBarProps> = ({
	address,
	currentAddress,
	onAddressClick,
}) => (
	<div
		className="flex items-center gap-x-1.5 py-4 cursor-pointer  mx-auto bg-white z-10"
		onClick={onAddressClick}
	>
		<img src={distance} alt="location" className="w-6 h-6" />
		<p className="sm:text-sm text-xs text-[#1D2939] font-normal truncate w-10/12 align-middle">
			{address?.label || currentAddress || "Fetching address..."}
		</p>
		<FiChevronDown className="text-[#667085]" />
	</div>
);

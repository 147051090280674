import React, { useMemo } from "react";
import { BusinessService } from "../../types/business";
import { formatDuration } from "../../utils/formatters";
import line from "../../assets/images/Line.png";
import { FiChevronRight } from "react-icons/fi";

interface ServiceListProps {
	services: BusinessService[];
	onServiceSelect: (service: BusinessService) => void;
}

export const ServiceList: React.FC<ServiceListProps> = ({
	services,
	onServiceSelect,
}) => {
	console.log("services", services);
	return (
		<div className="py-4">
			{services.map((service, index) => {
				const displayPrice =
					service.home_service_price !== null
						? service.home_service_price
						: service.walk_in_price !== null
						? service.walk_in_price
						: "0";
				return (
					<div
						className="py-4 border-b last:border-b-0 cursor-pointer"
						key={index}
						onClick={() => onServiceSelect(service)}
					>
						<div className="flex items-center justify-between sm:gap-x-3.5 gap-x-2">
							<div className="sm:text-sm text-sm font-normal">
								<p className="text-[#1D2939] font-bold">{service.service_name}</p>
								<p className="py-2 text-[#667085] lineclamp">
									{service.description}
								</p>
								<div className="flex items-center gap-x-2 sm:text-sm text-sm mt-1">
									<p className="text-[#667085]">
										{formatDuration(service.duration)}
									</p>
									<img src={line} alt="" className="h-5" />
									<p className="text-[#667085]">
										From ₦{Number(displayPrice).toLocaleString()}
									</p>
								</div>
							</div>
							<div className="flex items-center gap-x-2">
								<div className="w-5 h-5 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer">
									<FiChevronRight size={13} className="text-[#1D2939]" />
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddress } from "../../hooks/useAddress";
import { useBusinessData } from "../../hooks/useBusinessData";
import { BusinessService } from "../../types/business";
import { BusinessInfo } from "../../Components/Business/BusinessInfo";
import { BusinessHeader } from "../../Components/Business/BusinessHeader";
import { LoadingSkeleton } from "../../Components/Business/LoadingSkeleton";
import { AddressBar } from "../../Components/Business/AddressBar";
import { TabContent } from "../../Components/Business/TabContent";
import { NoDataFound } from "../../Components/Business/NoDataFound";
import { AddressDialog } from "../../Components/Business/AddressDialog";
import { ImageDialog } from "../../Components/Business/ImageDialog";
import { convertToMinutes } from "../../utils/formatters";

export default function Home() {
	const location = useLocation();
	const navigate = useNavigate();
	const query = new URLSearchParams(location.search);
	const businessId = query.get("id");

	const [tab, setTab] = useState<number>(
		parseInt(localStorage.getItem("tabFlag") || "0")
	);
	const [isOpen, setIsOpen] = useState(false);
	const [isOpen1, setIsOpen1] = useState(false);
	const [img, setImg] = useState<string>();

	const { currentAddress, address, handleAddressChange } = useAddress();
	const { data, loader, getBusinessData } = useBusinessData(businessId);

	useEffect(() => {
		window.scrollTo(0, 0);
		clearLocalStorage();
		getBusinessData();
	}, [businessId]);

	const clearLocalStorage = () => {
		const keysToRemove = [
			"butler",
			"butlerId",
			"categoryId",
			"date",
			"mainService",
			"moreDiscover",
			"notes",
			"selectedAddon",
			"selectedService",
			"selectedTime",
			"serviceDetails",
			"serviceId",
			"timeSlot",
			"tabFlag",
			"bookingId",
			"completeBooking",
		];
		keysToRemove.forEach((key) => localStorage.removeItem(key));
	};

	const handleServiceSelect = (service: BusinessService) => {
		localStorage.setItem(
			"address",
			typeof address === "object"
				? address?.label
				: address || currentAddress || "Fetching address..."
		);
		localStorage.setItem("serviceId", service.id);
		localStorage.setItem("categoryId", service.category_id.toString());

		const pricingDetails = {
			mainPrice: Number(service.price),
			addonPrice: 0,
			totalPrice: Number(service.price),
			totalService: 1,
			totalDuration: convertToMinutes(service.duration),
			totalAddonTime: 0,
		};
		localStorage.setItem("serviceDetails", JSON.stringify(pricingDetails));

		navigate(`/service/${service.id}/${service.category_id}`);
	};

	return (
		<div>
			<div className="mb-2">
				<div className="px-2 sticky top-0 z-10 bg-white mt-3">
					<BusinessHeader />
				</div>
				<div className="container sm:max-w-full mx-auto">
					<AddressBar
						address={address}
						currentAddress={currentAddress}
						onAddressClick={() => setIsOpen(true)}
					/>
				</div>
				<div className="sm:w-[30rem] sm:mx-auto">
					<div>
						<img
							src={data.cover_image}
							className="h-52 w-full object-cover"
							alt="cover Image"
						/>
					</div>
					<div className="relative -mt-9 mx-4">
						<img
							src={data.business_image}
							alt="business logo"
							className="w-[70px] h-[70px] mb-3 rounded-full border-2 border-white  object-cover"
						/>
					</div>
				</div>
				<div className=" container sm:max-w-full mx-auto">
					{loader ? (
						<LoadingSkeleton />
					) : data.business_name ? (
						<>
							<BusinessInfo
								data={data}
								currentTab={tab}
								onTabChange={(newTab) => {
									localStorage.setItem("tabFlag", newTab.toString());
									setTab(newTab);
								}}
							/>
							<TabContent
								tab={tab}
								data={data}
								onServiceSelect={handleServiceSelect}
								onImageClick={(image) => {
									setIsOpen1(true);
									setImg(image);
								}}
							/>
						</>
					) : (
						<NoDataFound />
					)}
				</div>
			</div>
			<AddressDialog
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				address={address}
				currentAddress={currentAddress}
				onAddressChange={handleAddressChange}
			/>

			<ImageDialog
				isOpen={isOpen1}
				onClose={() => setIsOpen1(false)}
				image={img}
			/>
		</div>
	);
}

import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LoadingSkeleton } from "../../Components/Business/LoadingSkeleton";
import { useServiceDetails } from "../../hooks/useServiceDetails";
import { useMoreDiscover } from "../../hooks/useMoreDiscover";
import { useServiceType } from "../../hooks/useServiceType";
import { useAddonServices } from "../../hooks/useAddonServices";
import { ServiceHeader } from "../../Components/Service/ServiceHeader";
import { ServiceImages } from "../../Components/Service/ServiceImages";
import { ServiceDescription } from "../../Components/Service/ServiceDescription";
import { formatDuration } from "../../utils/formatters";
import { ServiceTypeSelection } from "../../Components/Service/ServiceTypeSelection";
import { AddonServices } from "../../Components/Service/AddonServices";
import { MoreToDiscover } from "../../Components/Service/MoreToDiscover";
import { BottomBar } from "../../Components/Service/BottomBar";
import { ServiceDetails as IServiceDetails } from "../../types/service";

export default function ServiceDetails() {
	const navigate = useNavigate();
	const { id, cid } = useParams<{ id: string; cid: string }>();

	const {
		// serviceDetails,
		singleService,
		selectedService,
		loading,
		handleServiceBack,
	} = useServiceDetails(id as string);

	const { moreDiscover, handleNavigate } = useMoreDiscover(
		id as string,
		cid as string
	);

	const { serviceType, handleServiceTypeChange } = useServiceType();

	const {
		totalPrice,
		totalTime,
		selectedServiceCount,
		totalAddonPrice,
		totalAddonTime,
		handleAddonSelection,
	} = useAddonServices(singleService?.addon_services || []);

	const handleBookNow = useCallback(() => {
		const updatedValues: IServiceDetails = {
			...JSON.parse(localStorage.getItem("serviceDetails") || "{}"),
			addonPrice: totalAddonPrice,
			totalPrice,
			totalService: selectedServiceCount,
			totalDuration: totalTime,
			totalAddonTime,
		};

		localStorage.setItem("serviceDetails", JSON.stringify(updatedValues));
		localStorage.setItem("mainService", JSON.stringify(singleService));
		localStorage.setItem("selectedService", JSON.stringify(selectedService));
		localStorage.setItem("moreDiscover", JSON.stringify(moreDiscover));

		navigate("/butler");
	}, [
		totalAddonPrice,
		totalPrice,
		selectedServiceCount,
		totalTime,
		totalAddonTime,
		singleService,
		selectedService,
		moreDiscover,
		navigate,
	]);

	if (loading) {
		return <LoadingSkeleton />;
	}

	return (
		<div>
			<div className="sm:w-[30rem] sm:mx-auto">
				<ServiceHeader onBack={handleServiceBack} />
				<ServiceImages images={singleService?.service_images} />
				<ServiceDescription
					service={singleService}
					formatDuration={formatDuration}
				/>
			</div>
			<ServiceTypeSelection
					serviceType={serviceType}
					onChange={handleServiceTypeChange}
					service={singleService}
				/>

			<div className="container mx-auto">
				

				<AddonServices
					addonServices={singleService?.addon_services}
					onSelect={handleAddonSelection}
					formatDuration={formatDuration}
				/>

				<MoreToDiscover
					data={moreDiscover}
					onNavigate={handleNavigate}
					navigate={navigate}
				/>
			</div>

			<BottomBar
				selectedService={selectedService}
				serviceType={serviceType}
				singleService={singleService}
				totalPrice={totalPrice}
				selectedServiceCount={selectedServiceCount}
				totalTime={totalTime}
				onBack={handleServiceBack}
				onBookNow={handleBookNow}
			/>
		</div>
	);
}

import React, { useMemo } from "react";
import { BusinessService } from "../../types/service";
import { FaStar } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";

import line from "../../assets/images/Line.png";
import { formatTotalTime } from "../../utils/formatters";

interface BottomBarProps {
	selectedService: Record<string, any>;
	serviceType: {
		home_service: boolean;
		walk_in: boolean;
	};
	singleService: BusinessService | null;
	totalPrice: number;
	selectedServiceCount: number;
	totalTime: number;
	onBack: () => void;
	onBookNow: () => void;
}

export const BottomBar: React.FC<BottomBarProps> = ({
	selectedService,
	serviceType,
	singleService,
	totalPrice: addOnPrice,
	selectedServiceCount,
	totalTime,
	onBack,
	onBookNow,
}) => {
	const currentPrice = useMemo(() => {
		return serviceType.home_service
			? singleService?.home_service_price
			: serviceType.walk_in
			? singleService?.walk_in_price
			: 0;
	}, [
		serviceType.home_service,
		serviceType.walk_in,
		singleService?.home_service_price,
		singleService?.walk_in_price,
	]);

	const finalSelectedServiceCount = useMemo(
		() => (currentPrice! > 0 ? selectedServiceCount + 1 : 0),
		[currentPrice, selectedServiceCount]
	);

	const finalPrice = useMemo(() => {
		return currentPrice! > 0 ? addOnPrice + currentPrice! : 0;
	}, [addOnPrice, currentPrice]);

	if (!singleService) return null;

	return (
		<div className="sm:w-[30rem] sm:mx-auto mt-5 fixed inset-x-0 bottom-0 bg-white border-t border-[#EAECF0] pt-3">
			<div>
				<div
					className="flex items-center justify-between container mx-auto cursor-pointer"
					onClick={onBack}
				>
					<div className="flex items-center gap-x-3">
						<img
							src={selectedService.business_image}
							alt="business logo"
							className="rounded-full h-10 w-10 object-cover"
						/>
						<div className="sm:text-sm text-xs">
							<p className="font-medium text-[#1D2939]">
								{selectedService.business_name}
							</p>
							<p className="flex items-center gap-x-1 text-[#667085]">
								<FaStar className="w-2.5 h-2.5" />
								{selectedService.average_review_stars} (
								{selectedService?.review?.length})
							</p>
						</div>
					</div>
					<div className="w-5 h-5 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer">
						<FiChevronRight size={13} className="text-[#1D2939]" />
					</div>
				</div>
			</div>

			<div className="sm:w-[30rem] sm:mx-auto my-4 border-t border-[#EAECF0] pt-3">
				<div className="flex items-center justify-between container mx-auto">
					<div>
						<p className="heading-font text-[#1D2939] text-lg font-medium sm:text-base">
							₦{Number(finalPrice).toLocaleString()}
						</p>
						<div className="flex items-center gap-x-2">
							<p className="text-gray-500 text-xs sm:text-sm">
								{finalSelectedServiceCount}{" "}
								{finalSelectedServiceCount <= 1 ? "service" : "services"}
							</p>
							<img src={line} className="h-3 mt-0.5" alt="" />
							<p className="text-gray-500 text-xs sm:text-sm">
								{formatTotalTime(totalTime)}
							</p>
						</div>
					</div>
					{finalPrice ? (
						<button
							className="bg-[#8877D8] text-white rounded-xl sm:py-3 sm:px-7 py-3 px-4 font-medium text-center sm:text-lg"
							onClick={onBookNow}
						>
							Book Now
						</button>
					) : null}
				</div>
			</div>
		</div>
	);
};

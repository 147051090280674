import { useState, useCallback } from "react";

export const useServiceType = () => {
	const [serviceType, setServiceType] = useState({
		home_service: false,
		walk_in: false,
	});

	const handleServiceTypeChange = useCallback(
		(type: "home_service" | "walk_in") => {
			setServiceType((prev) => ({
				home_service: type === "home_service" ? !prev.home_service : false,
				walk_in: type === "walk_in" ? !prev.walk_in : false,
			}));
		},
		[]
	);

	return { serviceType, handleServiceTypeChange };
};
